
.theroy-detail {
    padding: 20px;
    font-size: 16px;
    color: #333;
    .back {
        text-align: end;
    }
    .type {
        font-weight: 700;
    }
    .title {
        margin: 20px;
    }
    .options {
        margin-bottom: 20px;
        .options-item {
            margin-top: 20px;
        }
        .options-item:first-child {
            margin-top: 0;
        }
    }
    .result-box {
        padding: 10px;
        border: 1px solid #aaa;
        .item {
            margin-bottom: 20px;
        }
        .result {
            color: #1cb064;
        }
        .result::before {
            content: "答案：";
        }
        .answer::before {
            content: "解析: ";
        }
    }
}
